import React from "react";
import ContentBlock from "../content-block";
import IconGrid from "../icon-grid";
import pythonIcon from "../../images/icons/python.svg"
import djangoIcon from "../../images/icons/django.svg";
import javascriptIcon from "../../images/icons/javascript.svg"
import dockerIcon from "../../images/icons/docker.svg"
import linuxIcon from "../../images/icons/linux.svg"
import ansibleIcon from "../../images/icons/ansible.svg"
import googleIcon from "../../images/icons/google.svg"
import scrumIcon from "../../images/icons/scrum.svg";

const ExpertiseContent = () => (
  <ContentBlock title="Expertise">
    <div className="col-md-6">
      <p>
        My focus is to contribute in a hands-on way. I prefer to do this within
        the Scrum framework.
        <br />
        I'm comfortable with Backend, Frontend and DevOps and I use programming
        languages like Python and JavaScript.
      </p>
      <p>
        Also I can help with strategic decisions to help accelerating existing
        teams by applying my former experience.
      </p>
    </div>
    <div className="col-md-6 d-none d-md-block">
      <IconGrid
        icons={[
          { icon: pythonIcon, name: "Python" },
          { icon: djangoIcon, name: "Django" },
          { icon: javascriptIcon, name: "JavaScript"},
          { icon: dockerIcon, name: "Docker" },
          { icon: linuxIcon, name: "Linux"},
          { icon: ansibleIcon, name: "Ansible"},
          { icon: googleIcon, name: "GAS", title:"Google App Scripts"},
          { icon: scrumIcon, name: "Scrum"}
        ]}
      />
    </div>
  </ContentBlock>
);

export default ExpertiseContent;
