import React from "react";
import SEO from '../components/seo';
import Layout from '../components/layout';
import IntroductionContent from '../components/homepage/introduction-content';
import ExpertiseContent from '../components/homepage/expertise-content';
import ConnectContent from '../components/homepage/connect-content';
import RecentProjectsContent from '../components/homepage/recent-projects';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['CuriousBits', 'Therry van Neerven', 'freelance', 'webdevelopment', 'full stack', 'python', 'javascript', 'devops']} />
    <IntroductionContent/>
    <ExpertiseContent/>
    <RecentProjectsContent/>
    <ConnectContent/>
  </Layout>
);

export default IndexPage;
