import React from "react";
import ContentBlock from "../content-block";
import IconGrid from "../icon-grid";
import envelopeIcon from "../../images/icons/envelope.svg"
import linkedinIcon from "../../images/icons/linkedin.svg"
import meetupIcon from "../../images/icons/meetup.svg"
import githubIcon from "../../images/icons/github.svg"

class LargeEmoji extends React.Component {
  render(children) {
    return (
        <span style={{ fontSize: "2rem" }}>
          { this.props.children }
        </span>
    )
  }
}

const GetinTouchBlock = () => (
  <div className="col-md-6 order-md-12">
    <p>
      Now it is time to realize your dreams and to tackle the challenges ahead
      of you. CuriousBits can help you personally, but also by bringing you in
      touch with people within our network of highly skilled individuals.
      <br />
      Good things start with a nice chat while having a{" "}
      <LargeEmoji>☕</LargeEmoji> or <LargeEmoji>🍺</LargeEmoji>.
    </p>
    <p>So what are you waiting for?</p>
    <a
      href="mailto:therry.van.neerven@curiousbits.nl"
      className="btn btn-outline-dark"
      title="Mail to CuriousBits"
    >
      Contact me now!
    </a>
  </div>
);

const SocialsBlock = () => (
  <div className="col-md-6 order-md-0">
    <IconGrid
      icons={[
        {
          icon: envelopeIcon,
          name: "Mail",
          href: "mailto:therry.van.neerven@curiousbits.nl"
        },
        {
          icon: linkedinIcon,
          name: "LinkedIn",
          href: "https://www.linkedin.com/in/therryvanneerven/"
        },
        {
          icon: githubIcon,
          name: "Github",
          href: "https://github.com/Ecno92" },
        {
          icon: meetupIcon,
          name: "Meetup",
          href: "http://www.meetup.com/members/145841212/"
        }
      ]}
    />
  </div>
);
const ConnectContent = () => (
  <ContentBlock title="Let's Connect!">
    <SocialsBlock />
    <GetinTouchBlock />
  </ContentBlock>
);

export default ConnectContent;
