import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames/bind';

class ProjectReview extends React.Component {
  render() {
    return (
      <>
        <div className="container" style={{marginTop: '2.5rem'}}>
          <div className="row">
            <div className="col text-center align-self-center">
                <img
                  src={this.props.logo}
                  alt={this.props.company}
                  className="d-sm-inline"
                  height="65"
                />
            </div>
            <div className={ classNames(
              'col',
              {
                'order-first': this.props.review_first
              }

            ) }>
              <p> {this.props.review}</p>
              <p><a href={this.props.author_link}>{this.props.author}</a></p>
            </div>
          </div>
        </div>
      </>
    );
  }
}


ProjectReview.propTypes = {
  logo: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  review: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  author_link: PropTypes.string.isRequired,
  review_first: PropTypes.bool
}

ProjectReview.defaultProps = {
  review_first: false
}

export default ProjectReview;
