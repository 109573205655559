import React from "react";
import PropTypes from "prop-types";

const IconGrid = ({ icons }) => (
  <div className="icongrid d-flex flex-wrap flex-row">
    {icons.map((i, index) => (
      <a
        className={
          "icongrid__icon p-2 " +
          (i.href ? "btn-outline-dark icongrid__icon--clickable" : "")
        }
        title={i.title || i.name}
        href={i.href}
        target={i.href ? "_blank" : ""}
        rel={i.href ? "noopener noreferrer" : ""}
        key={index}
      >
        <span className="icongrid__icon__content">
            <img
              className="icongrid__icon__img"
              src={i.icon}
              alt={`${i.name}-logo`}
            />
          <br />
          {i.name}
        </span>
      </a>
    ))}
  </div>
);

IconGrid.propTypes = {
  icons:  PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]).isRequired,
    name: PropTypes.string.isRequired
  })).isRequired
};

export default IconGrid;
