import React from "react";
import ContentBlock from "../content-block";
import ProjectReview from "../project-review";
import jouwwebLogo from "../../images/logo/jouwweb.svg"
import nxpLogo from "../../images/logo/nxp.svg"

const RecentProjectsContent = () => {
  return (
  <ContentBlock title="Recent Projects">
    <ProjectReview
      logo={jouwwebLogo}
      project="Consultancy: Infrastructure migration"
      review="Therry is a nice guy to work with. Using his experience as a developer and lead, Therry helped us determine the best strategy to scale our infrastructure. During the process he had impact on all major decisions ranging from the decision to move the cloud, choosing the cloud platform, and deciding on which technology to use for container clustering."
      company="JouwWeb"
      author="Roel van Duijnhoven (CTO JouwWeb)"
      author_link="https://www.linkedin.com/in/roelvanduijnhoven/"
    />
  </ContentBlock>
  );
}

export default RecentProjectsContent;


    // <ProjectReview
    //   logo={nxpLogo}
    //   project="Development: Access Management Solution"
    //   review="This is dummy text, but it really helps to fill the page. I recommend to do this all the time."
    //   company="NXP"
    //   author="John Doe (Product Owner Process Automation Squad)"
    //   author_link="https://www.linkedin.com/in/roel-engelen-93914714/"
    //   review_first="1"
    // />
