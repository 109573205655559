import React from "react";
import ContentBlock from "../content-block";
import imgTherry from "../../images/therry.png";

const IntroductionContent = () => (
  <ContentBlock title="">
    <div className="col-md-6">
      <p>
        The world is complex and always changing. My name is Therry van Neerven
        and through CuriousBits I provide services to create{" "}
        <b>effective software solutions</b> by combining pragmatic thinking with
        modern technology using a <b>true full stack approach</b> and a bit of
        curiosity.
      </p>
    </div>
    <div className="col-md-6 text-center">
      <img
        src={imgTherry}
        alt="Therry van Neerven"
        className="img-fluid d-none d-sm-inline"
        width="180"
        height="180"
      />
    </div>
  </ContentBlock>
);

export default IntroductionContent;
